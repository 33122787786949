<template>
  <div>
    <transition name="slide-fade">
      <div class="flex items-center justify-center">
        <span class="transition-all flex">
          <span
            class="transition-all flex justify-center items-center font-bold text-center"
            :class="[
              small ? 'text-green mt-1' : 'px-6',
              isMobile() ? 'text-sm' : 'text-base'
            ]"
          >
            <span v-if="icon" class="flex justify-center items-center mr-3">
              <i class="fas" :class="icon"></i>
            </span>
            {{ label }}
          </span>
        </span>
        <input
          v-bind:checked="modelValue"
          @input="$emit('update:modelValue', $event.target.checked)"
          @focus="focus = !focus"
          @blur="focus = !focus"
          class="outline-none px-3 rounded-md "
          :class="[small ? 'h-4 w-4 ml-3' : 'h-10 w-10 ml-6']"
          type="checkbox"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { isMobile } from "@/global/services/mixins";
export default {
  name: "Checkbox",
  emits: ["update:modelValue"],
  props: {
    modelValue: Boolean,
    label: String,
    icon: String,
    small: {
      type: Boolean,
      default: false
    },
    effect: String
  },
  setup() {
    const functions = {
      isMobile
    };
    return { ...functions };
  }
};
</script>

<style scoped>
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
}
</style>
