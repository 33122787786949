<template>
  <div class="grid grid-cols-12 grid-rows-6 gap-6 w-full px-6">
    <div class=" col-span-3 row-span-6 ">
      <div
        @click="addEmployee = !addEmployee"
        class="flex items-center
        cursor-pointer text-sm w-full h-12 md:h14"
      >
        <span
          class="flex flex-shrink-0 justify-center items-center text-base md:text-xl bg-yellow rounded-full w-12 h-12 md:w-14 md:h-14 z-10 shadow-md"
        >
          <i class="fas fa-user-plus"></i>
        </span>
        <span
          class="bg-white pl-6 rounded-md -ml-3 z-0 w-full text-green-darkest"
        >
          <p class="block text-sm md:text-base font-bold md:my-2">
            {{ t(`settings.addEmployee`) }}
          </p>
        </span>
      </div>
      <EmployeeList class="w-full mt-6" v-model="selectedEmployee" />
    </div>
    <div class="col-span-9 row-span-5">
      <table
        v-if="selectedEmployee !== undefined && selectedEmployee !== '*'"
        class="grid"
      >
        <tr
          class="border-b-2 border-green-dark flex justify-between w-full py-3"
        >
          <td class="grid place-items-end">
            <p>Den ansatte er tildelt leder beføjelser:</p>
          </td>
          <td class="text-yellow grid place-items-end">
            <p v-if="selectedEmployee.data().leader">Ja</p>
            <p v-if="!selectedEmployee.data().leader">Nej</p>
          </td>
          <td class="flex justify-end">
            <Button
              v-if="!selectedEmployee.data().leader"
              class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
              label="Tildel leder beføjelser"
              @click="createLeader(selectedEmployee)"
            />
            <Button
              v-if="selectedEmployee.data().leader"
              class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
              label="Fjern leder beføjelser"
              @click="createLeader(selectedEmployee)"
            />
          </td>
        </tr>
        <tr
          class="border-b-2 border-green-dark flex justify-between w-full py-3"
        >
          <td class="w-1/2">
            <p class="text-lg font-bold">Kontrakter</p>
            <div
              v-for="c in selectedEmployee.data().hours"
              :key="c.month"
              class="relative ml-4 mr-8 my-3 shadow-md bg-white border-green border-l-8 text-green-dark p-3 flex flex-col justify-evenly items-center"
            >
              <p>
                Gældende fra den:
                <span class="text-green font-bold">{{
                  c.date + "-" + (c.month + 1) + "-" + c.year
                }}</span>
              </p>
              <p v-if="c.value > 0">
                Maks timer om ugen:
                <span class="text-green font-bold">{{ c.value }}</span>
              </p>
              <p v-else>Timelønnet</p>
            </div>
          </td>
          <td class="grid place-items-center">
            <p class="text-lg font-bold mb-3">Lav en ny kontrakt</p>
            <Input
              class=""
              labelWidth="w-56"
              inputWidth="w-16"
              label="Maks arbejdstimer om ugen:"
              type="number"
              v-model="hours"
            />
            <div
              class=" w-64 text-green flex items-center justify-between my-3"
            >
              <span
                @click="decreasePeriod()"
                class="h-10 w-10 flex justify-center items-center text-yellow hover:text-green transition-all cursor-pointer"
              >
                <i class="fas fa-chevron-left"></i>
              </span>
              <p class="font-bold text-green text-center">
                {{ "Vælg startende " + t(`wageperiod`).toLowerCase() }}
              </p>
              <span
                @click="increasePeriod()"
                class="h-10 w-10 flex justify-center items-center"
                :class="[
                  isEndPeriod()
                    ? 'text-green'
                    : 'text-yellow hover:text-green transition-all cursor-pointer'
                ]"
              >
                <i class="fas fa-chevron-right"></i>
              </span>
            </div>
            <div class="flex justify-evenly w-full mb-6">
              <DatePicker
                v-model="wagePeriodStart"
                width="w-24"
                class="text-green-darkest bg-white"
                :disabled="true"
                :icon="false"
              />
              <p class="text-green text-center">-</p>
              <DatePicker
                v-model="wagePeriodEnd"
                width="w-24"
                class="text-green-darkest bg-white"
                :disabled="true"
                :icon="false"
              />
            </div>
            <Button
              class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
              label="Gem"
              @click="submitNewWage()"
            />
          </td>
        </tr>
        <tr
          v-if="location.hostname !== 'app.farmhelp.dk'"
          class="border-b-2 border-green-dark"
        >
          <td class="text-yellow">
            <Button
              class="bg-red-500 hover:bg-red-600 my-3"
              label="Slet medarbejder"
              @click="showModalDeleteEmployee = true"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
  <Modal @closeModal="addEmployee = false" :visible="addEmployee">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-user-plus"></i>
    </span>
    <p class="text-lg font-semibold">
      Tilføj en ny ansat til din driftsenhed.
    </p>
    <p v-if="userFarm.multipleUnits" class="text-base text-center mt-6 w-3/4">
      Vælg
      <span class="text-yellow font font-bold">Tilføj eksisterede</span>, hvis
      vedkommende allerede er ansat på en anden driftsenhed. Ellers vælg
      <span class="text-yellow font font-bold">Tilføj ny</span>.
    </p>
    <div class="flex mt-6 w-3/4 justify-evenly">
      <Button
        v-if="userFarm.multipleUnits"
        class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
        label="Tilføj eksisterende"
        @click="
          existingEmployee = true;
          addEmployee = false;
        "
      />
      <Button
        class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
        label="Tilføj ny"
        @click="
          newEmployee = true;
          addEmployee = false;
        "
      />
    </div>
  </Modal>
  <Modal @closeModal="newEmployee = false" :visible="newEmployee">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-user-plus"></i>
    </span>
    <p class="text-lg font-semibold">
      Tilføj en ny ansat til din driftsenhed.
    </p>
    <form
      @keyup.enter="createNewEmployee"
      class="w-full flex flex-wrap justify-center mt-6"
    >
      <Input
        class="w-full md:w-2/3 mb-6"
        labelWidth="w-4/12"
        inputWidth="w-8/12"
        :error="errorCode === 'emailNotValid' ? true : false"
        errorMessage="Ugyldig e-mail"
        icon="fa-at"
        placeholder="Den ansattes mail . . ."
        v-model="newEmployeeForm.email"
      />
      <Input
        class="w-full md:w-2/3 mb-6"
        labelWidth="w-4/12"
        inputWidth="w-8/12"
        :error="errorCode === 'firstNotValid' ? true : false"
        errorMessage="Indtast et fornavn"
        label="Fornavn"
        placeholder="Den ansattes fornavn . . ."
        v-model="newEmployeeForm.firstName"
      />
      <Input
        class="w-full md:w-2/3 mb-6"
        labelWidth="w-4/12"
        inputWidth="w-8/12"
        :error="errorCode === 'lastNotValid' ? true : false"
        errorMessage="Indtast et efternavn"
        label="Efternavn"
        placeholder="Den ansattes efternavn . . ."
        v-model="newEmployeeForm.lastName"
      />
      <Input
        class="w-full md:w-2/3 mb-6"
        labelWidth="w-4/12"
        inputWidth="w-8/12"
        :error="errorCode === 'phoneNotValid' ? true : false"
        errorMessage="Ugyldigt nummer"
        icon="fa-phone"
        placeholder="Den ansattes telefonnummer . . ."
        v-model="newEmployeeForm.phone"
      />
      <Checkbox
        v-if="!sick"
        label="Er den ansatte timelønnet?"
        class="w-full md:w-2/3 mb-6"
        v-model="hourlyWage"
        @change="hourlyWage"
      />
      <Input
        v-if="!hourlyWage"
        class="w-full md:w-2/3 mb-6"
        labelWidth="w-72"
        inputWidth="w-36"
        label="Maks arbejdstimer om ugen:"
        type="number"
        v-model="newEmployeeForm.hours[0].value"
      />
    </form>
    <Button
      class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
      label="Tilføj"
      @click="createNewEmployee"
    />
  </Modal>
  <Modal @closeModal="existingEmployee = false" :visible="existingEmployee">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-user-plus"></i>
    </span>
    <p class="text-lg font-semibold">
      Tilføj en eksisterende ansat til din driftsenhed.
    </p>
    <div class="flex items-center relative justify-center h-8 w-full my-6">
      <span
        class="flex cursor-pointer w-1/2"
        @click="selectingUser = !selectingUser"
      >
        <span
          class="flex justify-center text-green-darkest w-6 -mr-8 z-10 items-center"
        >
          <i class="fas fa-user text-lg mx-2"></i>
        </span>
        <p
          v-if="!selectedUser"
          class=" bg-white w-full text-center text-green-darkest block rounded-md p-1"
        >
          Vælg en ansat. . .
        </p>
        <p
          v-if="selectedUser"
          class=" bg-white w-full text-center text-green-darkest block rounded-md p-1"
        >
          {{ selectedUser.firstName }} {{ selectedUser.lastName }}
        </p>
        <span
          class="flex justify-center w-6 -ml-8 items-center text-green hover:text-yellow"
        >
          <i class="fas fa-chevron-down text-lg mx-2"></i>
        </span>
      </span>
      <ul
        v-if="selectingUser"
        class="absolute top-10 z-50 w-1/2 divide-y-2 flex flex-col text-center bg-white text-green shadow-md rounded-md overflow-y-scroll h-72"
      >
        <li
          class="py-2 hover:bg-yellow hover:text-white"
          v-for="user in farmUserList"
          :key="user"
          :value="user"
          @click="selectedUser = user"
        >
          {{ user.firstName }} {{ user.lastName }}
        </li>
      </ul>
    </div>
    <Button
      class="bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
      label="Tilføj"
      @click="createExistingEmployee"
    />
  </Modal>
  <Modal
    @closeModal="showModalDeleteEmployee = false"
    :visible="showModalDeleteEmployee"
  >
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-user-minus"></i>
    </span>
    <p class="text-lg font-semibold">
      Slet en ansat fra din driftsenhed.
    </p>
    <p class="text-base text-center mt-6 w-3/4">
      Den ansatte vil blive fjernet fra denne driftsenhed.
    </p>
    <div class="flex mt-6 w-3/4 justify-evenly">
      <Button
        class="bg-green-light hover:bg-green"
        label="Bekræft"
        @click="deleteEmployee(selectedEmployee)"
      />
      <Button
        class="bg-red-500 hover:bg-red-600"
        label="Anuller"
        @click="showModalDeleteEmployee = false"
      />
    </div>
  </Modal>
</template>

<script>
import { ref, watchEffect } from "vue";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import EmployeeList from "@/global/components/EmployeeList";
import Checkbox from "@/components/Checkbox";
import DatePicker from "@/components/DatePicker";
import { authentication } from "@/global/services/authentication";
import { units } from "@/global/services/units";
import { farm } from "@/global/services/farm";
import { systemState } from "@/global/services/systemState";
import { validateEmail } from "@/global/services/mixins";
import { employee } from "@/global/services/employee";
import { period } from "@/timeregistration/services/period";
import { errorLogging } from "@/global/services/errorLogging";

import { useI18n } from "vue-i18n";

export default {
  name: "TimeRegistrationSettings",
  components: {
    Modal,
    Input,
    Button,
    EmployeeList,
    Checkbox,
    DatePicker
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {},
        dk: {}
      }
    });
    const periodStart = new Date(period.start.value);
    const now = new Date();
    const state = {
      location,
      newEmployeeForm: ref({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        hours: [
          {
            date: now.getDate(),
            month: periodStart.getMonth(),
            year: periodStart.getFullYear(),
            value: 37
          }
        ]
      }),
      wagePeriodStart: ref(period.start.value),
      wagePeriodEnd: ref(period.end.value),
      hourlyWage: ref(false),
      selectedEmployee: ref(undefined),
      hours: ref(0),
      getOwnerUid: farm.getOwnerUid,
      newEmployee: ref(false),
      addEmployee: ref(false),

      showModalDeleteEmployee: ref(false),

      existingEmployee: ref(false),
      unitUserList: units.getSelectedUnitEmployees(true),
      userFarm: farm.getFarm(true)
    };

    watchEffect(() => {
      if (
        state.selectedEmployee.value &&
        state.selectedEmployee.value !== "*"
      ) {
        state.selectedEmployee.value = state.unitUserList.value.find(
          e => e.id === state.selectedEmployee.value.id
        );
      }
    });

    const functions = {
      deleteEmployee: emp => {
        employee.deleteEmployee(emp.id);
        state.showModalDeleteEmployee.value = false;
      },
      isEndPeriod: function() {
        return period.endPeriodDate.value === state.wagePeriodEnd.value;
      },
      decreasePeriod: function() {
        const ds = new Date(state.wagePeriodStart.value);
        const de = new Date(state.wagePeriodEnd.value);
        ds.setMonth(ds.getMonth() - 1);
        de.setMonth(de.getMonth() - 1);
        state.wagePeriodStart.value = ds.getTime();
        state.wagePeriodEnd.value = de.getTime();
      },
      increasePeriod: function() {
        if (!(state.wagePeriodEnd.value === period.endPeriodDate.value)) {
          const ds = new Date(state.wagePeriodStart.value);
          const de = new Date(state.wagePeriodEnd.value);
          ds.setMonth(ds.getMonth() + 1);
          de.setMonth(de.getMonth() + 1);
          state.wagePeriodStart.value = ds.getTime();
          state.wagePeriodEnd.value = de.getTime();
        }
      },
      submitNewWage: function() {
        const start = new Date(state.wagePeriodStart.value);
        const wages = state.selectedEmployee.value.data().hours;
        const max = wages.reduce(function(prev, current) {
          return (prev.month > current.month && prev.year >= current.year) ||
            prev.year >= current.year
            ? prev
            : current;
        });
        if (
          (max.month >= start.getMonth() && max.year >= start.getFullYear()) ||
          max.year > start.getFullYear()
        ) {
          errorLogging.setWarning(
            "Der findes allerede en kontrakt for samme måned ellere nyere."
          );
        } else {
          wages.push({
            month: start.getMonth(),
            year: start.getFullYear(),
            date: start.getDate(),
            value: state.hours.value
          });
          employee.set(state.selectedEmployee.value, { hours: wages });
        }
      }
    };

    watchEffect(
      () =>
        (state.newEmployeeForm.value.hours[0].value = state.hourlyWage.value
          ? 0
          : 37)
    );

    watchEffect(() => {
      if (
        state.selectedEmployee.value !== undefined &&
        state.selectedEmployee.value !== "*"
      ) {
        if (state.selectedEmployee.value.data().hours) {
          const endIdx = state.selectedEmployee.value.data().hours.length - 1;
          state.hours.value = state.selectedEmployee.value.data().hours[
            endIdx
          ].value;
        }
      }
    });
    /* watch(state.hours, () => {
      employee.set(state.selectedEmployee.value, { hours: {} state.hours.value });
    }); */

    const createNewEmployee = () => {
      if (!validateEmail(state.newEmployeeForm.value.email)) {
        state.errorCode = "emailNotValid";
      } else if (
        !/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
          state.newEmployeeForm.value.firstName
        )
      ) {
        state.errorCode = "firstNotValid";
      } else if (
        !/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
          state.newEmployeeForm.value.lastName
        )
      ) {
        state.errorCode = "lastNotValid";
      } else if (
        !/^[+]{0,1}[0-9]{0,2}[\s]{0,1}[0-9]{8,16}$/.test(
          state.newEmployeeForm.value.phone
        )
      ) {
        state.errorCode.value = "phoneNotValid";
      } else {
        systemState.startProcessing();
        const data = Object.assign({}, state.newEmployeeForm.value);
        data["units"] = [units.getSelectedUnit().id];
        data["selectedUnit"] = authentication.getUserSelectedUnit();
        employee
          .create(data)
          .then(response => {
            if (response.data !== undefined) {
              if (response.data.code === "200") {
                state.newEmployee.value = !state.newEmployee.value;
              }
            }
            systemState.stopProcessing();
          })
          .catch(() => {
            systemState.stopProcessing();
          });

        state.errorCode.value = "";
      }
    };

    const createExistingEmployee = () => {
      systemState.startProcessing();
      const unitsList = [...state.selectedUser.value.units];
      unitsList.push(units.getSelectedUnit().id);
      const selectedUnit = authentication.getUserSelectedUnit();
      const data = { selectedUnit, units: unitsList };
      authentication
        .updateUser(state.selectedUser.value.uid, data)
        .then(() => {
          state.existingEmployee.value = false;
          systemState.stopProcessing();
        })
        .catch(() => {
          systemState.stopProcessing();
        });
    };

    return {
      t,
      ...state,
      ...functions,
      createExistingEmployee,
      createNewEmployee,
      createLeader: authentication.createLeader
    };
  }
};
</script>
