<template>
  <div class="">
    <ul class="w-full">
      <template v-if="unitEmployeeList !== []">
        <li
          v-for="employee in unitEmployeeList.filter(
            e => e.data().deleted === null
          )"
          :key="employee.id"
          :value="employee"
          @click="$emit('update:modelValue', employeeSelected(employee))"
          class="flex items-center
        cursor-pointer w-full py-1 px-3 hover:bg-green shadow-md rounded-md mb-6"
          :class="[modelValue === employee ? 'bg-green' : 'bg-green-dark']"
        >
          <span class="rounded-md w-full flex">
            <p class="block text-base font-bold">
              {{ employee.data().firstName }} {{ employee.data().lastName }}
            </p>
          </span>
        </li>
        <li
          v-for="employee in unitEmployeeList.filter(
            e => e.data().deleted !== null
          )"
          :key="employee.id"
          :value="employee"
          @click="$emit('update:modelValue', employeeSelected(employee))"
          class="flex items-center
        cursor-pointer w-full py-1 px-3 hover:text-white hover:bg-green shadow-md rounded-md mb-6"
          :class="[
            modelValue === employee
              ? 'bg-green text-white'
              : 'bg-green-darkest text-green'
          ]"
        >
          <span class="rounded-md w-full flex">
            <p class="block text-base font-bold">
              {{ employee.data().firstName }} {{ employee.data().lastName }}
            </p>
            <span
              v-if="employee.data().deleted"
              class="flex justify-center items-center ml-3"
            >
              <i class="fas fa-ban"></i>
            </span>
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { units } from "@/global/services/units";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { employee } from "@/global/services/employee";

export default {
  name: "SelectorEmployee",
  emits: ["update:modelValue"],
  props: {
    modelValue: [Object, String],
    all: {
      default: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { t } = useI18n();
    const state = {
      selectingEmployee: ref(false),
      unitEmployeeList: units.getSelectedUnitEmployees(true)
    };

    const functions = {
      employeeSelected: employee => {
        return employee;
      }
    };

    watchEffect(() => {
      if (props.modelValue === undefined) {
        const emp = state.unitEmployeeList.value.filter(
          e => e.data().deleted === null
        )[0];
        emit("update:modelValue", emp);
      }
    });

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
